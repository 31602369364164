<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link v-bind:to="'/stores/'" style="text-decoration: none">
                <v-btn rounded color="#05a2fa" dark>
                  <v-icon color="white"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>

          <v-col cols="14" sm="8" md="4">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn rounded color="#05a2fa" dark @click="dialog = true">
                  <v-icon color="white"> mdi-plus-circle </v-icon>
                  <v-card-text> Add a New Zone </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="8" md="8" class="itemsLayout">
            <v-row dense>
              <v-col cols="3" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="#05a2fa"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                v-for="card in newItems"
                :key="card.zoneId"
                :cols="3"
                class="pb-3"
              >
                <router-link
                  v-bind:to="{
                    path: 'zones/' + card.zoneId,
                  }"
                  style="text-decoration: none"
                >
                  <v-card height="200" width="150" style="cursor: pointer">
                    <v-img
                      :src="`https://cdn.vuetifyjs.com/images/cards/house.jpg`"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      width="150"
                      height="100"
                    >
                      <v-card-title style="height: 40px">
                        <div class="headerClass">
                          <v-icon
                            style="font-size: 20px; color: #05a2fa"
                            color="#ffffffff"
                            class="mb-1"
                          >
                            mdi-map-marker-radius
                          </v-icon>

                          {{ card.zoneName }}
                        </div>
                      </v-card-title>
                    </v-img>

                    <v-card-text>
                      <div class="headerClass">Code : {{ card.zoneCode }}</div>
                      <div class="headerClass">
                        <v-chip
                          label
                          color="green"
                          class="white--text"
                          style="height: 25px"
                          v-if="card.active === true"
                        >
                          Active
                        </v-chip>
                        <v-chip
                          label
                          color="red"
                          class="white--text"
                          style="height: 25px"
                          v-if="card.active === false"
                        >
                          In Active
                        </v-chip>
                      </div>
                    </v-card-text>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="4" md="4">
            <v-card>
              <div style="float: right; margin-top: 10px; margin-right: 10px">
                <v-icon
                  color="#05a2fa"
                  @click="editItem()"
                  style="margin-right: 5px"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon color="#FF0000" @click="deleteItem()">
                  mdi-trash-can-outline
                </v-icon>
              </div>

              <v-card-title>
                <!-- Store Id : {{ storeInfo.storeId }} <br /> -->
                Store Code : {{ storeInfo.storeCode }}
              </v-card-title>
              <v-card-title style="font-size: 20px">
                Store Name :
                {{ storeInfo.storeName }}
              </v-card-title>

              <v-card-text>
                Description :
                {{ storeInfo.description }}
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <v-chip
                  label
                  color="green"
                  class="white--text"
                  v-if="storeInfo.active === true"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  color="red"
                  class="white--text"
                  v-if="storeInfo.active === false"
                >
                  In Active
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-col cols="14" sm="8" md="8" style="margin-top: -25px">
        <v-pagination
          v-model="page"
          :length="pageCount"
          @click="changePage"
          circle
          color="#05a2fa"
        ></v-pagination>
      </v-col>
      <v-container @click="dialogTrash = true" style="cursor: pointer">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-card>
              <v-card-text class="text-center" style="font-size: 15px">
                <v-icon color="#05a2fa" style="font-size: 25px">
                  mdi-delete-empty
                </v-icon>
                Trash
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add A New Zone</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="ZoneForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.zoneName"
                      label="Zone Name"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.zoneCode"
                      label="Zone Code"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.active"
                      :items="status"
                      item-text="text"
                      item-value="value"
                      label="Zone Status"
                      outlined
                    />
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-select
                      v-model="editedItem.storeId"
                      :items="storeInfo"
                      item-text="storeName"
                      item-value="storeId"
                      label="Store Id"
                    />
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Store</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="StoreForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedStoreInfo.storeName"
                      label="Store Name"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedStoreInfo.storeCode"
                      label="Store Code"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="editedStoreInfo.active"
                      :items="status"
                      item-text="text"
                      item-value="value"
                      label="Store Status"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEdit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-data-table :headers="headers" :items="trashed" :search="search">
            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="#05a2fa" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to restore this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 450px;
  overflow: hidden;
  overflow-y: auto;
}
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
<script>
export default {
  data() {
    return {
      dialog: false,
      dialogEdit: false,
      dateModal: false,
      dialogDelete: false,
      dialogRestore: false,
      dialogPermanentDelete: false,
      dialogTrash: false,
      search: "",
      headers: [
        { text: "zoneId", value: "zoneId" },
        { text: "zoneCode", value: "zoneCode" },
        { text: "storeId", value: "storeId" },
        { text: "zoneName", value: "zoneName" },

        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],
      balance: [],
      status: [
        { text: "Active", value: true },
        { text: "InActive", value: false },
      ],
      storeInfo: {},
      editedStoreInfo: {
        storeName: "",
        storeCode: "",
        active: "",
      },
      defaultStoreInfo: {
        storeName: "",
        storeCode: "",
        active: "",
      },
      zones: [],
      allCustomers: [],

      editedIndex: -1,
      editedItem: {
        zoneName: "",
        zoneCode: "",
        active: true,
        storeId: "",
      },
      defaultItem: {
        zoneName: "",
        zoneCode: "",
        active: true,
        storeId: "",
      },
      storeId: "",
      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      newItems: [],
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add A New Account" : "Edit Event";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.changePage();
    },
  },

  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let url = vars[0];
      url = parseInt(url);
      this.storeId = url;
      this.editedItem.storeId = url;

      this.$http.get("/Zone").then((response) => {
        this.zones = response.data.data.filter((a) => a.storeId === url);
        this.changePage();
      });
      this.$http.get(`/Zone/GetTrashed/${url}`).then((response) => {
        this.trashed = response.data.data;
      });
      // this.$http.get("/Customer").then((response) => {
      //   this.allCustomers = response.data.data;
      // });

      this.$http.get(`Store/${url}`).then((response) => {
        this.storeInfo = response.data.data;
      });
    },
    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      var count = this.zones.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }
      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.zones.slice(newStart, newEnd);
      this.newItems = items;
    },

    editItem() {
      this.editedStoreInfo = Object.assign({}, this.storeInfo);
      this.dialogEdit = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.storeId = this.storeId;
      });
    },
    closeEdit() {
      this.dialogEdit = false;
    },

    deleteItem() {
      this.dialogDelete = true;
    },

    trashItem() {
      this.$http
        .post("/Store/Trash", this.storeInfo)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight",
            });
            this.$router.push("/stores/");
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },

    restoreItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },
    restoreItemConfm() {
      this.$http
        .post("/Zone/Restore", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.shift(response.data.data);
            this.zones.unshift(response.data.data);
            this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var Id = this.editedItem.zoneId;
      this.$http
        .delete("/Zone/" + Id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Zone deleted successfully.", "Success", {
              position: "topRight",
            });
            this.trashed.shift(response.data.data);
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.success === false) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      //this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      if (this.editedItem.zoneName && this.editedItem.zoneCode) {
        this.save();
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },
    save() {
      this.$http
        .post("/Zone", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.zones.unshift(response.data.data);
            this.updateData();
            this.$toast.success("Zone added successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.close();
    },
    saveEdit() {
      const data = this.editedStoreInfo;
      var Id = this.editedStoreInfo.storeId;
      this.$http
        .patch("/Store/Update", data)
        .then((response) => {
          if (response.data.success === true) {
            this.storeInfo = response.data.data;
            this.$toast.success("Store updated successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Updating Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
      this.dialogEdit = false;
    },

    // getStatusColor(data) {
    //   switch (data) {
    //     case "true":
    //       return "success";
    //     case "false":
    //       return "error";
    //     default:
    //       return "";
    //   }
    // },

    getStatusColor(data) {
      switch (data) {
        case 1:
          return "green";
        case 2:
          return "black";
        case 3:
          return "blue";
        case 4:
          return "red";
        default:
          return "";
      }
    },
  },
};
</script>
